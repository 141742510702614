import { EyeIcon, EyeSlashIcon, FolderIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useSitesContext } from "../../../context/SitesContext";
import MultiSelect, {
  Option,
} from "../../../shared/components/general/multi-select";
import SiteReportsTable from "./sites-reports-table";
import apiService from "../../../services/api-service";
import toast from "react-hot-toast";
import LoadingTable from "../../../shared/components/general/loading-table";

const filters = [
  { id: 1, name: "Visibles" },
  { id: 2, name: "No visibles" },
];

export type SiteReportItem = {
  id: number;
  name: string;
  sections: number;
  inventories: number;
  visible: boolean;
};

function SiteReports() {
  const { sites } = useSitesContext();
  const visibleSitesCount = sites.filter((site) => site.visible).length;
  const [selectedFilter, setSelectedFilter] = React.useState<Option[]>(filters);
  const [tableSites, setTableSites] = React.useState<SiteReportItem[] | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    async function fetchSites() {
      setLoading(true);
      let queryParam = "";
      if (selectedFilter.length === 1) {
        const filterName = selectedFilter[0].name;
        const queryValue = filterName === "Visibles" ? true : false;
        queryParam = `?visible=${queryValue}`;
      }
      const { data, error } = await apiService.get(
        `reports/sites${queryParam}`
      );

      if (error) {
        setLoading(false);
        toast.error("Error al obtener los sitios");
      }

      setTableSites(data);
      setLoading(false);
    }

    fetchSites();
  }, [selectedFilter]);

  return (
    <div>
      <div className="w-full border-b border-gray-400 mb-4">
        <p className="text-xl text-left font-bold">Resumen de Páginas</p>
      </div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-[#990001] p-3">
              <FolderIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Numero de paginass
            </p>
          </dt>
          <dd className="ml-16 flex items-baseline">
            <p className="text-2xl font-semibold text-gray-900">
              {sites.length}
            </p>
          </dd>
        </div>
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-[#990001] p-3">
              <EyeIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              Visibles
            </p>
          </dt>
          <dd className="ml-16 flex items-baseline">
            <p className="text-2xl font-semibold text-gray-900">
              {visibleSitesCount}
            </p>
          </dd>
        </div>
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-[#990001] p-3">
              <EyeSlashIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">
              No Visibles
            </p>
          </dt>
          <dd className="ml-16 flex items-baseline">
            <p className="text-2xl font-semibold text-gray-900">
              {sites.length - visibleSitesCount}
            </p>
          </dd>
        </div>
      </dl>
      <div className="w-full border-b border-gray-400 mb-4 mt-5">
        <p className="text-xl text-left font-bold">Resumen de Secciones</p>
      </div>
      <MultiSelect
        label="Filtrar por:"
        options={filters}
        onChange={(selected) => {
          setSelectedFilter(selected as Option[]);
        }}
        className="w-96"
        inLineLabel
      />

      {loading ? (
        <LoadingTable nCols={5} nRows={5} />
      ) : (
        <SiteReportsTable sites={tableSites!} />
      )}
    </div>
  );
}

export default SiteReports;
