import React from "react";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";

type Props = {
  title: string;
  children: React.ReactNode;
  onToggle?: (open: boolean) => void;
};

function Collapse({ title, children, onToggle }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (onToggle) onToggle(isOpen);
  }, [isOpen, onToggle]);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className="flex w-full items-center px-4 py-3 text-left text-sm font-medium focus:outline-none border-b border-gray-400"
            onClick={() => setIsOpen(!isOpen)} // Track open state
          >
            <div className="mr-3">
              {isOpen ? (
                <EyeIcon className="h-5 w-5 text-gray-600" />
              ) : (
                <EyeSlashIcon className="h-5 w-5 text-gray-600" />
              )}
            </div>
            <div className="flex-1 flex flex-col">
              <p className="text-base">{title}</p>
            </div>
            <ChevronUpIcon
              className={`h-5 w-5 text-gray-600 transition-transform ${
                !isOpen ? "rotate-180" : ""
              }`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="p-4 w-full">{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Collapse;
