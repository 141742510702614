import React from "react";

type SingularDatePickerProps = {
  date: string | null;
  onDateChange: (date: string) => void;
  label?: string;
  className?: string;
  placeholder?: string;
};

const CustomDatePicker: React.FC<SingularDatePickerProps> = ({
  date,
  onDateChange,
  label = "Selecciona una fecha",
  className = "",
  placeholder = "",
}) => {
  return (
    <div className={` ${className}`}>
      {label && (
        <label
          htmlFor="datePicker"
          className="block text-sm font-medium text-gray-900 mb-1"
        >
          {label}
        </label>
      )}
      <input
        id="datePicker"
        type="date"
        value={date || ""}
        onChange={(e) => onDateChange(e.target.value)}
        placeholder={placeholder}
        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
      />
    </div>
  );
};

export default CustomDatePicker;
