import React from "react";

interface LoadingTableI {
  nCols: number;
  nRows?: number;
}

const styles = {
  tr: "border-b-[1px] border-gray-200",
  th_td: "py-[10px] px-[16px] w-[10%]",
};

export default function LoadingTable({ nCols, nRows }: LoadingTableI) {
  const cols = new Array(nCols).fill("");

  return (
    <table className="mt-7 w-full border-collapse">
      <thead>
        <tr className={styles.tr}>
          {cols.map((c, i) => (
            <th scope="col" key={i} className={styles.th_td}>
              <div className="div animate-pulse w-16 h-3 rounded-md bg-gray-400"></div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="items-center">
        {new Array(nRows || 5).fill(0).map((r, i) => (
          <tr className={styles.tr} key={i}>
            {cols.map((c, j) => (
              <td key={j} className={styles.th_td}>
                <div className="div animate-pulse w-10 h-2 rounded-md bg-gray-400"></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
