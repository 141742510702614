import React from "react";
import DashboardLayout from "../shared/components/layout/dashboard-layout";
import ReportsLayout from "./components/reports-layout";

function ReportsPage() {
  return (
    <DashboardLayout isAdmin showSidebar={false}>
      <ReportsLayout />
    </DashboardLayout>
  );
}

export default ReportsPage;
