import React from "react";
import { Section, MediaLink } from "../../shared/types/section";
import CustomDropdown from "../../shared/components/general/custom-dropdown";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { CatalogueItem } from "../../shared/types/catalogues";
import useCanEdit, { useCanEditCatalogue } from "../hooks/use-canEdit";

type Props = {
  section?: Section | null;
  catalogueItem?: CatalogueItem | null;
  onLinkDelete: (MediaLink: MediaLink) => void;
  mediaLinks: MediaLink[];
  onLinkEditClicked: (link: MediaLink) => void;
};

type LinkProps = {
  link: MediaLink;
};

function Link({ link }: LinkProps) {
  return (
    <div className="flex items-center justify-between  ">
      <a
        href={link.url}
        className="font-medium text-blue-600 underline text-base"
        target="_blank"
        rel="noreferrer"
      >
        {link.linkTitle}
      </a>
    </div>
  );
}

function MediaLinks({
  section,
  onLinkDelete,
  onLinkEditClicked,
  catalogueItem,
  mediaLinks,
}: Props) {
  const canEditSection = useCanEdit();
  const canEditCatalogue = useCanEditCatalogue(catalogueItem?.catalogue!);
  const canEdit = catalogueItem ? canEditCatalogue : canEditSection;

  function getDeleteUrl() {
    if (catalogueItem) {
      return `catalogues/${catalogueItem.catalogue!.id}/items/${
        catalogueItem.id
      }/links`;
    }
    return `sections/${section!.id}/links`;
  }

  async function handleDelete(link: MediaLink) {
    onLinkDelete(link);
    const { error } = await apiService.delete(`${getDeleteUrl()}/${link.id}`);

    if (error) {
      toast.error("Error al eliminar link");
      return;
    }
  }

  if (mediaLinks?.length === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col items-start justify-start 0 w-full space-y-3 mt-14 rounded-md ">
      {mediaLinks && <p className="text-base ">Informacion Relacionada:</p>}

      <div className="flex w-full  items-center justify-start">
        {mediaLinks?.map((link) => (
          <div className="flex items-center mr-10 space-x-2" key={link.id}>
            <Link key={link.id} link={link} />
            {canEdit && (
              <CustomDropdown
                withThreeDots
                title=""
                menuItems={[
                  {
                    title: "Editar",
                    onClick: () => {
                      onLinkEditClicked(link);
                    },
                  },
                  {
                    title: "Eliminar",
                    onClick: () => {
                      handleDelete(link);
                    },
                  },
                ]}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MediaLinks;
