import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import downLoadReport from "../../shared/utils/download-report";
import { Catalogue } from "../../shared/types/catalogues";
import CustomDropdown from "../../shared/components/general/custom-dropdown";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomFileUploader from "../../shared/components/general/custom-file-uploader";
import CustomButton from "../../shared/components/general/custom-button";
import { useState } from "react";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";

type Props = {
  catalogue: Catalogue;
  setEditMode: (value: boolean) => void;
  setOpenDeleteDialog: (value: boolean) => void;
  setIsCreateDialogOpen: (value: boolean) => void;
  setCatalogueOnView: React.Dispatch<React.SetStateAction<Catalogue>>;
};

function CatalogueTableActions({
  catalogue,
  setEditMode,
  setOpenDeleteDialog,
  setIsCreateDialogOpen,
  setCatalogueOnView,
}: Props) {
  const [isBatchDialogOpen, setIsBatchDialogOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null | undefined>(null);
  const [isBatchUploadLoading, setIsBatchUploadLoading] = useState(false);

  const catalogueActionItems = [
    {
      title: "Nuevo",
      onClick: () => setIsCreateDialogOpen(true),
    },
    {
      title: "Editar",
      onClick: () => setEditMode(true),
    },
    {
      title: "Eliminar",
      onClick: () => setOpenDeleteDialog(true),
    },
  ];

  const downloadActions = [
    {
      title: "Excel",
      onClick: () => downLoadReport("excel", [catalogue.id]),
    },
    {
      title: "CSV",
      onClick: () => downLoadReport("csv", [catalogue.id]),
    },
  ];

  async function handleBatchUpload() {
    if (!csvFile) {
      return;
    }

    setIsBatchUploadLoading(true);

    const { data, error } = await apiService.uploadFile(
      `catalogues/${catalogue.id}/batchCreate`,
      csvFile
    );

    if (error) {
      toast.error(error);
      setCsvFile(null);
      setIsBatchUploadLoading(false);
      return;
    }
    const createdItems = data.items;
    setIsBatchUploadLoading(false);
    setIsBatchDialogOpen(false);
    setCsvFile(null);
    setCatalogueOnView((prev) => ({
      ...prev,
      items: [...prev.items, ...createdItems],
    }));
    toast.success(`Se crearon ${createdItems.length} elementos`);
  }

  return (
    <div className="flex items-center space-x-2">
      <ArrowUpTrayIcon
        className="h-6 w-6 cursor-pointer"
        onClick={() => setIsBatchDialogOpen(true)}
      />
      <CustomDropdown
        title="Acciones"
        menuItems={downloadActions}
        Icon={<ArrowDownTrayIcon className="h-6 w-6 cursor-pointer" />}
      />
      <CustomDropdown title="Acciones" menuItems={catalogueActionItems} />

      <CustomDialog
        dialogTitle="Carga Batch"
        open={isBatchDialogOpen}
        setOpen={() => []}
      >
        <div className="space-y-4">
          <p className="text-xs text-left">
            Esta opción te permite crear múltiples entradas en este inventario.
            Para ello, sube un archivo CSV que incluya los siguientes campos en
            el mismo orden:
          </p>

          <ul className="list-disc list-inside text-xs text-left pl-4">
            <li>Nombre</li>
            <li>Descripción</li>
            <li>Artefacto</li>
            <li>Clasificación</li>
            <li>Subclasificación</li>
          </ul>

          <CustomFileUploader
            onFileChange={(file) => setCsvFile(file)}
            multiple={false}
            allowedFileTypes=".csv"
          />
        </div>

        <div className="flex space-x-2 items-center justify-end mt-5">
          <CustomButton
            title="Cancelar"
            filled={false}
            onClick={() => setIsBatchDialogOpen(false)}
          />
          <CustomButton
            title="Subir"
            onClick={() => handleBatchUpload()}
            loading={isBatchUploadLoading}
          />
        </div>
      </CustomDialog>
    </div>
  );
}

export default CatalogueTableActions;
