import { useSitesContext } from "../../context/SitesContext";

function useCurrentSite() {
  const { sites } = useSitesContext();

  function getPageName() {
    const path = window.location.pathname;

    const pathArray = path.split("/");

    if (pathArray.length === 3) {
      return pathArray[1];
    }

    const pageName = pathArray[pathArray.length - 1];
    return pageName;
  }

  return sites.find((site) => site.path === getPageName());
}

export default useCurrentSite;
