import React, { useEffect } from "react";
import { Site } from "../../shared/types/sites";
import {
  ChevronDownIcon,
  EyeIcon,
  EyeSlashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import apiService from "../../services/api-service";
import { useSitesContext } from "../../context/SitesContext";
import CustomInput from "../../shared/components/general/custom-input";
import Pagination from "../../shared/components/general/Pagination";
import MultiSelect from "../../shared/components/general/multi-select";

type Props = {
  sites: Site[];
  onEditButtonClick: (site: Site) => void;
};

const visibleFilters = [
  { id: 1, name: "Visibles" },
  { id: 2, name: "No visibles" },
];

function SitesTable({ sites, onEditButtonClick }: Props) {
  const { onSiteUpdated } = useSitesContext();
  const [filter, setFilter] = React.useState({
    name: "",
    description: "",
    url: "",
    visible: "",
  });
  const [activeFilter, setActiveFilter] = React.useState<
    "name" | "description" | "url" | "visible" | null
  >(null);

  const filterRefs = React.useRef<{ [key: string]: HTMLDivElement | null }>({
    name: null,
    description: null,
    url: null,
    visible: null,
  });
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredSites = filterSites();

  function filterSites() {
    const filteredItems = sites.filter(
      (site) =>
        site.name.toLowerCase().includes(filter.name.toLowerCase()) &&
        site.description
          .toLowerCase()
          .includes(filter.description.toLowerCase()) &&
        site.path.toLowerCase().includes(filter.url.toLowerCase())
    );

    if (filter.visible === "true") {
      return filteredItems.filter((site) => site.visible);
    }
    if (filter.visible === "false") {
      return filteredItems.filter((site) => !site.visible);
    }
    return filteredItems;
  }

  const currentItems = filteredSites.slice(indexOfFirstItem, indexOfLastItem);

  async function updateSiteVisibility(site: Site) {
    onSiteUpdated({ ...site, visible: !site.visible });
    await apiService.put(`sites/${site.id}`, {
      visible: !site.visible,
    });
  }

  function VisibilityButton({ site }: { site: Site }) {
    return (
      <button className="mx-3" onClick={() => updateSiteVisibility(site)}>
        {site.visible ? (
          <EyeIcon className="w-4" />
        ) : (
          <EyeSlashIcon className="w-4" />
        )}
      </button>
    );
  }

  useEffect(() => {
    function handleFocus(event: FocusEvent) {
      // Check if the focused element is inside any of the filter dropdowns
      const isFocusInsideFilter = Object.keys(filterRefs.current).some(
        (key) => {
          const ref = filterRefs.current[key];
          return ref && ref.contains(event.target as Node);
        }
      );

      // If focus is not inside any filter dropdown, close all filters
      if (!isFocusInsideFilter) {
        setActiveFilter(null);
      }
    }

    document.addEventListener("mousedown", handleFocus);
    document.addEventListener("focusin", handleFocus);

    return () => {
      document.removeEventListener("mousedown", handleFocus);
      document.removeEventListener("focusin", handleFocus);
    };
  }, []);

  function getSiteHref(site: Site) {
    const origin = window.location.origin;
    return `${origin}/${site.path}`;
  }

  function renderFilterColumn(
    columnKey: "name" | "description" | "url",
    placeholder: string
  ) {
    return (
      <div ref={(el) => (filterRefs.current[columnKey] = el)}>
        <button
          className="ml-2 rounded-md p-1 bg-gray-100 text-gray-900 group-hover:bg-gray-200"
          onClick={() => {
            setActiveFilter((prev) => (prev === columnKey ? null : columnKey));
          }}
        >
          <ChevronDownIcon className="size-4" />
        </button>
        {activeFilter === columnKey && (
          <div className="absolute mt-2 flex bg-white items-center space-x-4 shadow-lg border rounded p-4 z-10">
            <CustomInput
              placeholder={placeholder}
              value={filter[columnKey]}
              onChange={(e) => {
                setFilter((prev) => ({
                  name: columnKey === "name" ? e.target.value : "",
                  description:
                    columnKey === "description" ? e.target.value : "",
                  url:
                    columnKey === "url" ? e.target.value.replace("/", "") : "",
                  visible: "",
                }));
                setPage(1);
              }}
            />
            <XMarkIcon
              className="h-5 w-5 text-red-500 cursor-pointer"
              onClick={() => {
                setFilter((prev) => ({
                  ...prev,
                  [columnKey]: "",
                }));
                setActiveFilter(null);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  function renderVisibleFilter() {
    return (
      <div ref={(el) => (filterRefs.current.visible = el)}>
        <button
          className="ml-2 rounded-md p-1 bg-gray-100 text-gray-900 group-hover:bg-gray-200"
          onClick={() => {
            setActiveFilter((prev) => (prev === "visible" ? null : "visible"));
          }}
        >
          <ChevronDownIcon className="size-4" />
        </button>
        {activeFilter === "visible" && (
          <div className="absolute mt-2 flex bg-white items-center space-x-4 shadow-lg border rounded p-4 z-10">
            <MultiSelect
              label=""
              options={visibleFilters}
              onChange={(selected) => {
                const visibleFilter =
                  selected.length === visibleFilters.length
                    ? ""
                    : selected[0]?.name === "Visibles"
                    ? "true"
                    : "false";

                setFilter((prev) => ({
                  ...prev,
                  visible: visibleFilter,
                }));
                setPage(1);
              }}
              className="w-40"
              inLineLabel
              value={
                filter.visible === ""
                  ? visibleFilters
                  : filter.visible === "true"
                  ? [visibleFilters[0]]
                  : [visibleFilters[1]]
              }
            />
            <XMarkIcon
              className="h-5 w-5 text-red-500 cursor-pointer"
              onClick={() => {
                setFilter((prev) => ({
                  ...prev,
                  visible: "",
                }));
                setActiveFilter(null);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    <div className="group inline-flex">
                      Nombre
                      {renderFilterColumn("name", "Buscar por nombre")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <div className="group inline-flex">
                      Descripcion breve
                      {renderFilterColumn(
                        "description",
                        "Buscar por descripcion"
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <div className="group inline-flex">
                      URL
                      {renderFilterColumn("url", "Buscar por URL")}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <div className="group inline-flex">
                      Visible
                      {renderVisibleFilter()}
                    </div>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentItems.map((site) => (
                  <tr key={site.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {site.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate max-w-24">
                      {site.description}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-blue-500 underline cursor-pointer">
                      <a href={getSiteHref(site)} target="__blank">
                        /{site.path}
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <VisibilityButton site={site} />
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        onClick={() => onEditButtonClick(site)}
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="-mx-8">
        <Pagination
          currentPage={page}
          totalItems={filteredSites.length}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </>
  );
}

export default SitesTable;
