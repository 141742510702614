import React from "react";
import { SiteReportItem } from "./site-reports";
import Pagination from "../../../shared/components/general/Pagination";

type props = {
  sites: SiteReportItem[];
};

function SiteReportsTable({ sites }: props) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sites.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  function getSiteHref(site: SiteReportItem) {
    const origin = window.location.origin;
    return `${origin}/${site.name}`;
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-400 bg-[#990001] text-white">
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-sm font-semibold"
                >
                  #
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-sm font-semibold"
                >
                  Página
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-sm font-semibold"
                >
                  Visible
                </th>
                <th
                  scope="col"
                  className="py-2 pl-4 pr-4 text-left text-sm font-semibold sm:pr-0"
                >
                  Secciones
                </th>
                <th
                  scope="col"
                  className="py-2 pl-4 pr-4 text-left text-sm font-semibold sm:pr-0"
                >
                  Inventarios
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-400 bg-white">
              {currentItems.map((site, i) => (
                <tr
                  key={site.id}
                  className="divide-x divide-gray-400 0 last:border-b"
                >
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500">
                    {i + 1}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-blue-500  underline cursor-pointer">
                    <a href={getSiteHref(site)} target="__blank">
                      /{site.name}
                    </a>
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500">
                    {site.visible ? "Si" : "No"}
                  </td>
                  <td className="whitespace-nowrap p-2 text-sm text-gray-500">
                    {site.sections}
                  </td>
                  <td className="whitespace-nowrap py-2 px-2 text-sm text-gray-500 sm:pr-0">
                    {site.inventories}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        totalItems={sites.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default SiteReportsTable;
