import { ArchiveBoxIcon, FolderIcon } from "@heroicons/react/24/outline";
import React from "react";
import { twMerge } from "tailwind-merge";
import InventoryReports from "./inventory/inventory-reports";
import SiteReports from "./sites/site-reports";

enum Tab {
  SITES = "Paginas y Secciones",
  Inventories = "Inventarios",
}

function ReportsLayout() {
  const [currentTab, setCurrentTab] = React.useState(Tab.Inventories);
  const navigation = [
    {
      name: Tab.Inventories,
      current: currentTab === Tab.Inventories,
      icon: ArchiveBoxIcon,
    },
    {
      name: Tab.SITES,
      current: currentTab === Tab.SITES,
      icon: FolderIcon,
    },
  ];

  function Content() {
    if (currentTab === Tab.Inventories) {
      return <InventoryReports />;
    }

    return <SiteReports />;
  }

  return (
    <>
      <div className="flex mt-10 h-[calc(100vh-3.5rem)] overflow-hidden">
        <nav
          aria-label="Sidebar"
          className="flex flex-col w-64 p-4 shrink-0 border-r border-gray-200"
        >
          <p className="mb-5 text-xl">Reportes</p>

          <ul className="-mx-2 space-y-1">
            {navigation.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => setCurrentTab(item.name)}
                  className={twMerge(
                    item.current
                      ? "bg-gray-50 text-blue-600"
                      : "text-gray-700 hover:bg-gray-50 hover:text-blue-600",
                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold"
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={twMerge(
                      item.current
                        ? "text-blue-600"
                        : "text-gray-400 group-hover:text-blue-600",
                      "h-6 w-6 shrink-0"
                    )}
                  />
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <main className="flex-1 overflow-y-auto p-4">
          <div className=" mb-28">
            <Content />
          </div>
        </main>
      </div>
    </>
  );
}
export default ReportsLayout;
