import React, { useState, useCallback } from "react";
import Collapse from "../collapse";
import BarChart from "../charts/bar-chart";
import { useCategoriesContext } from "../../../categories/hooks/use-categories";
import { LoadingBarChart } from "../charts/loading-charts";
import apiService from "../../../services/api-service";
import { getCategorieNamesByIds } from "../../helpers/get-category-names";
import SubChildCollapse from "./subChild-collapse";

type Props = {
  categoryId: number;
};

type ReportData = {
  count: number;
  values: number[];
  labels: number[];
  subChildIds: number[];
};

function InventoryCollapse({ categoryId }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);
  const { parentCategories, getCategoriesByParent, childCategories } =
    useCategoriesContext();

  const category = parentCategories?.find(
    (category) => category.id === categoryId
  );

  const [reportData, setReportData] = useState<ReportData>({
    count: 0,
    values: [],
    labels: [],
    subChildIds: [],
  });

  const fetchReport = useCallback(async () => {
    if (dataFetched || loading) return;
    setLoading(true);
    setError(null);

    const { data: summaryData, error: sumarryError } =
      await apiService.post<ReportData>(
        "reports/inventory-parentCategory-summary",
        {
          parentCategoryId: categoryId,
        }
      );

    if (sumarryError) {
      setError(sumarryError);
      setLoading(false);
      setDataFetched(true);
      return;
    }

    setReportData(summaryData!);
    setLoading(false);
    setDataFetched(true);
  }, [categoryId, dataFetched, loading]);

  return (
    <Collapse
      title={category?.name || "Category"}
      onToggle={(isOpen) => {
        if (isOpen) fetchReport();
      }}
    >
      <div className="justify-center items-center w-full">
        {loading ? (
          <LoadingBarChart />
        ) : error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <>
            <p className="text-lg text-gray-600">
              Total de registros: {reportData.count}
            </p>
            <div className="flex-1 min-w-96 mx-auto">
              <BarChart
                labels={getCategorieNamesByIds(
                  reportData.labels,
                  childCategories || []
                )}
                values={reportData.values}
                title="Clasificaciónes"
                showValuesAsPercentage
              />
            </div>
            <div className="w-full border-b border-gray-400 my-4">
              <p className="text-xl text-left font-bold">
                Resumen por Sub-Clasificación
              </p>
            </div>
            <div className="ml-10">
              {getCategoriesByParent(categoryId)!.map((childCat, index) => (
                <SubChildCollapse key={childCat.id} childCategory={childCat} />
              ))}
            </div>
          </>
        )}
      </div>
    </Collapse>
  );
}

export default InventoryCollapse;
