import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../shared/components/general/custom-button";
import {
  ChevronDownIcon,
  EyeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { Catalogue } from "../../shared/types/catalogues";
import CreateCatalogueItemDialog from "./create-catalogue-item-dialog";
import apiService from "../../services/api-service";
import { toast } from "react-hot-toast";
import CustomDialog from "../../shared/components/general/custom-dialog";
import Pagination from "../../shared/components/general/Pagination";
import { useBasePageContext } from "../../context/BasePageContext";
import { Category } from "../../shared/types/categories";
import useCanEdit from "../../wiki/hooks/use-canEdit";
import CustomInput from "../../shared/components/general/custom-input";
import CatalogueTableActions from "./catalogue-table-actions";

type Props = {
  catalogue: Catalogue;
};
const itemsPerPage = 10;

function CatalogueTable({ catalogue }: Props) {
  const [catalogueOnView, setCatalogueOnView] = useState(catalogue);

  const { onCatalogueDeleted } = useBasePageContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const catalogueItems = catalogueOnView.items;
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [editedName, setEditedName] = React.useState(catalogue.name);
  const [editedDescription, setEditedDescription] = React.useState(
    catalogue.briefDescription
  );
  const filterPopupRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [filterDialogState, setFilterDialogState] = useState<
    Record<string, boolean>
  >({
    name: false,
    description: false,
    parentCategory: false,
  });

  const [filter, setFilter] = useState({ type: "", term: "" });

  const navigate = useNavigate();
  const canEdit = useCanEdit();

  const filteredItems = catalogueItems.filter((item) => {
    if (!filter.term || !filter.type) return true;

    const value =
      filter.type === "name"
        ? item.name
        : filter.type === "description"
        ? item.briefDescription
        : item.parentCategory?.name || "";

    return value.toLowerCase().includes(filter.term.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (type: string, term: string) => {
    setFilter({ type, term });
    setCurrentPage(1);
  };

  async function handleItemCreated(
    name: string,
    briefDescription: string,
    parentCategory: Category | null,
    childCategory: Category | null,
    subChildCategory: Category | null
  ) {
    const { data, error } = await apiService.post(
      `catalogues/${catalogue.id}/items`,
      {
        name,
        briefDescription,
        parentCategoryId: parentCategory?.id,
        childCategoryId: childCategory?.id,
        subChildCategoryId: subChildCategory?.id,
      }
    );

    if (error) {
      toast.error(error.message);
      return;
    }

    navigate(`/catalogo?id=${data.id}`);
  }

  async function deleteCatalogue() {
    const { error } = await apiService.delete(`catalogues/${catalogue.id}`);

    if (error) {
      toast.error("Error al eliminar el catalogo");
      setOpenDeleteDialog(false);
      return;
    }
    onCatalogueDeleted?.(catalogue.id);
    toast.success("Catalogo eliminado correctamente");
    setOpenDeleteDialog(false);
  }

  async function handleSaveCatalogue() {
    try {
      const { error } = await apiService.put(`catalogues/${catalogue.id}`, {
        name: editedName,
        briefDescription: editedDescription,
      });

      if (error) {
        toast.error("Error al guardar los cambios");
        return;
      }

      catalogue.name = editedName;
      catalogue.briefDescription = editedDescription;

      toast.success("Catálogo actualizado correctamente");
      setEditMode(false);
    } catch (error) {
      toast.error("Error al guardar los cambios");
    }
  }

  function handleDiscardChanges() {
    setEditedName(catalogue.name);
    setEditedDescription(catalogue.briefDescription);
    setEditMode(false);
  }

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      const newFilterDialogState = { ...filterDialogState };

      // Loop through the popup refs and check if the click is outside each popup
      Object.entries(filterPopupRefs.current).forEach(([key, popupRef]) => {
        if (popupRef && !popupRef.contains(event.target as Node)) {
          newFilterDialogState[key] = false; // Close dialog if the click is outside
        }
      });

      if (
        JSON.stringify(newFilterDialogState) !==
        JSON.stringify(filterDialogState)
      ) {
        setFilterDialogState(newFilterDialogState);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [filterPopupRefs, filterDialogState]);

  const renderFilter = (type: string, placeholder: string) => (
    <div ref={(el) => (filterPopupRefs.current[type] = el)}>
      <button
        className="ml-2 rounded-md p-1 bg-gray-100 text-gray-900 group-hover:bg-gray-200"
        onClick={() => {
          setFilterDialogState((prev) =>
            Object.keys(prev).reduce((acc, key) => {
              acc[key] = key === type ? !prev[key] : false;
              return acc;
            }, {} as Record<string, boolean>)
          );
        }}
      >
        <ChevronDownIcon className="size-4" />
      </button>
      {filterDialogState[type] && (
        <div className="absolute mt-2 flex bg-white items-center space-x-4 shadow-lg border rounded p-4 z-10">
          <CustomInput
            placeholder={placeholder}
            value={filter.type === type ? filter.term : ""}
            onChange={(e) => handleFilterChange(type, e.target.value)}
          />
          <XMarkIcon
            className="h-5 w-5 text-red-500 cursor-pointer"
            onClick={() => {
              handleFilterChange(type, ""); // Reset the filter for this type
              setFilterDialogState((prev) => ({
                ...prev,
                [type]: false, // Close the dialog
              }));
            }}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="mb-20 " id={`catalogo-${catalogue.id}`}>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto flex-grow">
            {editMode ? (
              <div className="space-y-2">
                <CustomInput
                  className="mb-2 max-w-xs"
                  placeholder="Nombre"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                />
                <CustomInput
                  className="max-w-md"
                  placeholder="Descripcion"
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              </div>
            ) : (
              <>
                <h1 className="text-2xl font-semibold leading-6 text-gray-900">
                  {catalogue.name}
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  {catalogue.briefDescription}
                </p>
              </>
            )}
          </div>
          {canEdit && (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none space-x-2">
              {editMode ? (
                <>
                  <CustomButton
                    title="Guardar"
                    onClick={handleSaveCatalogue}
                    filled={true}
                  />
                  <CustomButton
                    title="Cancelar"
                    onClick={handleDiscardChanges}
                    filled={false}
                  />
                </>
              ) : (
                <CatalogueTableActions
                  catalogue={catalogue}
                  setEditMode={setEditMode}
                  setCatalogueOnView={setCatalogueOnView}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                  setIsCreateDialogOpen={setIsCreateDialogOpen}
                />
              )}
            </div>
          )}
        </div>

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className=" pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      <div className="group inline-flex">
                        Nombre
                        {renderFilter("name", "Filtrar por nombre")}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Descripción corta
                        {renderFilter("description", "Filtrar por descripción")}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="group inline-flex">
                        Artefacto
                        {renderFilter(
                          "parentCategory",
                          "Filtrar por artefacto"
                        )}
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Detalles
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {currentItems?.map((item) => (
                    <tr key={item.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate max-w-[300px]">
                        {item.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate max-w-[210px]">
                        {item.briefDescription}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  truncate max-w-[210px]">
                        {item.parentCategory?.name}
                      </td>

                      <td className="whitespace-nowrap  px-7 py-4 text-sm">
                        <NavLink to={`/catalogo?id=${item.id}`}>
                          <EyeIcon className="h-6 w-6" />
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={filteredItems?.length || 0}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <CreateCatalogueItemDialog
        open={isCreateDialogOpen}
        setOpen={setIsCreateDialogOpen}
        onItemCreated={handleItemCreated}
      />
      <CustomDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        dialogTitle="¿Seguro?"
      >
        <p>Se borraran todos los datos asociados a este catalogo</p>

        <div className="w-full items-center mt-3">
          <CustomButton
            title="Regresar"
            onClick={() => setOpenDeleteDialog(false)}
            filled={false}
          />
          <CustomButton
            title="Eliminar"
            filled={false}
            onClick={() => {
              deleteCatalogue();
            }}
            className=" text-pantone ml-2 border-pantone"
          />
        </div>
      </CustomDialog>
    </>
  );
}

export default CatalogueTable;
