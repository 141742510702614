import React, { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CatalogueItem } from "../../shared/types/catalogues";
import CustomButton from "../../shared/components/general/custom-button";
import CustomInput from "../../shared/components/general/custom-input";
import CustomSelect from "../../shared/components/general/custom-select";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useCanEditCatalogue } from "../../wiki/hooks/use-canEdit";
import { useCategoriesContext } from "../../categories/hooks/use-categories";
import { Category } from "../../shared/types/categories";

type CatalogueHeaderProps = {
  catalogueItem: CatalogueItem;
  onCatalogueItemUpdated: (catalogueItem: CatalogueItem) => void;
};

function CatalogeHeader({
  catalogueItem,
  onCatalogueItemUpdated,
}: CatalogueHeaderProps) {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const canEdit = useCanEditCatalogue(catalogueItem?.catalogue!);

  const { parentCategories, getCategoriesByParent } = useCategoriesContext();

  const [formData, setFormData] = useState({
    name: catalogueItem.name,
    briefDescription: catalogueItem.briefDescription,
    parentCategoryId: catalogueItem.parentCategory?.id || null,
    childCategoryId: catalogueItem.childCategory?.id || null,
    subChildCategoryId: catalogueItem.subChildCategory?.id || null,
  });

  // Local states for selected categories
  const [parent, setParent] = useState<Category | null>(
    catalogueItem.parentCategory || null
  );
  const [child, setChild] = useState<Category | null>(
    catalogueItem.childCategory || null
  );
  const [subChild, setSubChild] = useState<Category | null>(
    catalogueItem.subChildCategory || null
  );

  useEffect(() => {
    // Sync local category states if catalogueItem changes
    setParent(catalogueItem.parentCategory || null);
    setChild(catalogueItem.childCategory || null);
    setSubChild(catalogueItem.subChildCategory || null);

    setFormData({
      name: catalogueItem.name,
      briefDescription: catalogueItem.briefDescription,
      parentCategoryId: catalogueItem.parentCategory?.id || null,
      childCategoryId: catalogueItem.childCategory?.id || null,
      subChildCategoryId: catalogueItem.subChildCategory?.id || null,
    });
  }, [catalogueItem]);

  // Helper to find a category by name from a given list of categories
  function findCategoryInListByName(
    list: Category[],
    name: string | null
  ): Category | null {
    if (!name) return null;
    return list.find((cat) => cat.name === name) || null;
  }

  function handleParentChange(catName: string | null) {
    const possibleParents = parentCategories || [];
    const selectedParent = findCategoryInListByName(possibleParents, catName);

    setParent(selectedParent);
    setFormData((prev) => ({
      ...prev,
      parentCategoryId: selectedParent?.id || null,
      childCategoryId: null,
      subChildCategoryId: null,
    }));

    // Reset child and subChild
    setChild(null);
    setSubChild(null);
  }

  function handleChildChange(catName: string | null) {
    if (!parent) {
      setChild(null);
      setFormData((prev) => ({
        ...prev,
        childCategoryId: null,
        subChildCategoryId: null,
      }));
      return;
    }

    const possibleChildren = getCategoriesByParent(parent.id);
    const selectedChild = findCategoryInListByName(possibleChildren, catName);

    setChild(selectedChild);
    setFormData((prev) => ({
      ...prev,
      childCategoryId: selectedChild?.id || null,
      subChildCategoryId: null,
    }));

    // Reset subChild
    setSubChild(null);
  }

  function handleSubChildChange(catName: string | null) {
    if (!child) {
      setSubChild(null);
      setFormData((prev) => ({
        ...prev,
        subChildCategoryId: null,
      }));
      return;
    }

    const possibleSubChildren = getCategoriesByParent(child.id);
    const selectedSubChild = findCategoryInListByName(
      possibleSubChildren,
      catName
    );

    setSubChild(selectedSubChild);
    setFormData((prev) => ({
      ...prev,
      subChildCategoryId: selectedSubChild?.id || null,
    }));
  }

  const handleSave = async () => {
    setLoading(true);
    const { error, data } = await apiService.put(
      `catalogues/${catalogueItem.catalogue?.id}/items/${catalogueItem.id}`,
      formData
    );

    if (error) {
      toast.error("Error al actualizar el catálogo");
      setLoading(false);
      return;
    }

    onCatalogueItemUpdated(data);

    toast.success("Catálogo actualizado exitosamente");
    setEditMode(false);
    setLoading(false);
  };

  const parentItems = parentCategories?.map((t) => t.name) || [];
  const childItems = parent
    ? getCategoriesByParent(parent.id).map((t) => t.name)
    : [];
  const subChildItems = child
    ? getCategoriesByParent(child.id).map((t) => t.name)
    : [];

  return (
    <div className="relative">
      {canEdit && editMode === false && (
        <div
          className="absolute top-0 right-0 cursor-pointer"
          onClick={() => setEditMode(true)}
        >
          <PencilIcon className="w-5 h-5" />
        </div>
      )}

      {editMode ? (
        <form className="flex flex-col mb-2">
          <p className="text-xs">Nombre</p>
          <CustomInput
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="mb-2  w-[200px]"
          />
          <p className="text-xs">Descripción breve</p>
          <CustomInput
            value={formData.briefDescription}
            onChange={(e) =>
              setFormData({ ...formData, briefDescription: e.target.value })
            }
            className="mb-2 w-[280px]"
          />

          {/* Category selectors in edit mode */}
          <div
            aria-label="Breadcrumb"
            className="flex items-center space-x-10 mt-4"
          >
            <CustomSelect
              label="Artefacto"
              items={parentItems}
              className="w-40"
              value={parent?.name || null}
              onChange={handleParentChange}
            />

            {parent && childItems.length > 0 && (
              <>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400 mt-8"
                />
                <CustomSelect
                  label="Clasificación"
                  items={childItems}
                  className="w-40"
                  value={child?.name || null}
                  onChange={handleChildChange}
                />
              </>
            )}

            {child && subChildItems.length > 0 && (
              <>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400 mt-8"
                />
                <CustomSelect
                  label="Subclasificación"
                  items={subChildItems}
                  className="w-40"
                  value={subChild?.name || null}
                  onChange={handleSubChildChange}
                />
              </>
            )}
          </div>
        </form>
      ) : (
        <>
          <p className="text-xl font-medium">{catalogueItem.name}</p>
          <p>{catalogueItem.briefDescription}</p>

          {/* View mode breadcrumb */}
          <div aria-label="Breadcrumb" className="flex mt-2">
            <ol className="flex items-center space-x-4">
              {catalogueItem.parentCategory && (
                <li>
                  <div className="flex items-center">
                    <p className="mr-4 text-sm font-medium text-gray-500">
                      {catalogueItem.parentCategory.name}
                    </p>
                    {(catalogueItem.childCategory ||
                      catalogueItem.subChildCategory) && (
                      <ChevronRightIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    )}
                  </div>
                </li>
              )}
              {catalogueItem.childCategory && (
                <li>
                  <div className="flex items-center">
                    <p className="mr-4 text-sm font-medium text-gray-500">
                      {catalogueItem.childCategory.name}
                    </p>
                    {catalogueItem.subChildCategory && (
                      <ChevronRightIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    )}
                  </div>
                </li>
              )}
              {catalogueItem.subChildCategory && (
                <li>
                  <div className="flex items-center">
                    <p className="mr-4 text-sm font-medium text-gray-500">
                      {catalogueItem.subChildCategory.name}
                    </p>
                  </div>
                </li>
              )}
            </ol>
          </div>
        </>
      )}

      {editMode && canEdit && (
        <div className="flex space-x-2 mt-3 ml-auto justify-end">
          <CustomButton
            title="Regresar"
            className="w-20 self-end"
            filled={false}
            onClick={() => {
              setEditMode(false);
            }}
          />
          <CustomButton
            title="Guardar"
            className="w-20 self-end"
            onClick={handleSave}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
}

export default CatalogeHeader;
