import toast from "react-hot-toast";
import apiService from "../../services/api-service";

export default async function downLoadReport(
  type: string,
  ids: number[],
  startDate?: string,
  endDate?: string
) {
  if (ids.length === 0) {
    return;
  }

  const { data, error } = await apiService.get(
    `reports/invetory-excel-download?ids=${ids.join(
      ","
    )}&type=${type}&startDate=${startDate}&endDate=${endDate}`,
    { responseType: "arraybuffer" }
  );

  if (error) {
    toast.error("Se produjo un error: " + error);
    return;
  }

  const isCsv = type === "csv";
  const mimeType = isCsv
    ? "text/csv"
    : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = isCsv ? "csv" : "xlsx";

  const blob = new Blob([data], { type: mimeType });

  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = `reporte-inventarios.${fileExtension}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(downloadUrl);
}
