import React from "react";
import { twMerge } from "tailwind-merge";
import UsersPage from "../../users/users-page";
import { getRoleByPermissions } from "../../users/utils/user-utils";
import { useAuthContext } from "../../context/AuthContext";
import CategoriesPage from "../../categories/categories-page";
import SitesPage from "../../sites/sites-page";

import { UsersIcon, FolderIcon, TagIcon } from "@heroicons/react/24/outline";

enum Tab {
  USERS = "Usuarios",
  SITES = "Paginas",
  Artifacts = "Catálogo de artefactos",
}

function AdminLayout() {
  const { user } = useAuthContext();
  const userRole = getRoleByPermissions(user!.permission);
  const [currentTab, setCurrentTab] = React.useState(
    userRole === "account-admin" ? Tab.USERS : Tab.SITES
  );
  const navigation = [
    {
      name: Tab.SITES,
      current: currentTab === Tab.SITES,
      roles: ["super-admin", "admin"],
      icon: FolderIcon,
    },
    {
      name: Tab.USERS,
      current: currentTab === Tab.USERS,
      roles: ["super-admin", "account-admin"],
      icon: UsersIcon,
    },
    {
      name: Tab.Artifacts,
      current: currentTab === Tab.Artifacts,
      roles: ["super-admin", "admin"],
      icon: TagIcon,
    },
  ];

  const filteredNavigation = navigation.filter((item) =>
    item.roles.includes(userRole)
  );

  function Content() {
    if (currentTab === Tab.USERS) {
      return <UsersPage />;
    }
    if (currentTab === Tab.Artifacts) {
      return <CategoriesPage />;
    }
    return <SitesPage />;
  }

  return (
    <>
      <div className="flex mt-10 h-[calc(100vh-3.5rem)] overflow-hidden">
        <nav
          aria-label="Sidebar"
          className="flex flex-col w-64 p-4 shrink-0 border-r border-gray-200"
        >
          <p className="mb-5 text-xl">Administración</p>

          <ul className="-mx-2 space-y-1">
            {filteredNavigation.map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => setCurrentTab(item.name)}
                  className={twMerge(
                    item.current
                      ? "bg-gray-50 text-blue-600"
                      : "text-gray-700 hover:bg-gray-50 hover:text-blue-600",
                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold"
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={twMerge(
                      item.current
                        ? "text-blue-600"
                        : "text-gray-400 group-hover:text-blue-600",
                      "h-6 w-6 shrink-0"
                    )}
                  />
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <main className="flex-1 overflow-y-auto p-4">
          <div className=" mb-28">
            <Content />
          </div>
        </main>
      </div>
    </>
  );
}
export default AdminLayout;
