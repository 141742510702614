import React, { useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { MediaFile, Section } from "../../shared/types/section";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { getFileLogoByFileName } from "../utils/file-utils";
import { LoadingFile } from "./LoadingSection";
import useCanEdit from "../hooks/use-canEdit";

type Props = {
  section: Section;
  file: MediaFile;
  onDelete: () => void;
  onEditClick: () => void;
};

function MediaFileContainer({ file, section, onDelete, onEditClick }: Props) {
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const canEdit = useCanEdit();

  const deleteFile = async () => {
    setLoadingDelete(true);
    const { error } = await apiService.delete(
      `sections/${section.id}/files/${file.id}`
    );
    if (error) {
      toast.error("Error al eliminar el archivo");
      setLoadingDelete(false);
      return;
    }
    setLoadingDelete(false);
    onDelete();
    toast.success("Archivo eliminado correctamente");
  };

  async function getPresignedUrl() {
    setLoading(true);
    const { data, error } = await apiService.get(
      `sections/${section.id}/fileSignedUrl/${file.id}`
    );

    if (error) {
      toast.error("Error al obtener el archivo");
      setLoading(false);
      return;
    }

    window.open(data, "_blank");
    setLoading(false);
  }

  if (loadingDelete) {
    return <LoadingFile />;
  }

  console.log(file);

  return (
    <div
      className="rounded-lg bg-[#F2F5F9] px-5 py-4 my-2 flex items-center cursor-pointer justify-between   max-w-[340px]"
      onClick={getPresignedUrl}
      key={file.fileName}
    >
      <div className="flex space-x-5 items-center">
        <div className="hidden rounded-lg border border-blue-200 bg-blue-50 lg:grid place-items-center w-10 h-10">
          <img
            src={getFileLogoByFileName(file.fileName)}
            alt="altan-redes"
            className="h-5 w-5  mx-auto object-fit"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-xs leading-5">{file.fileName}</span>
          <span className="text-[#97A3B6] text-xs leading-5 truncate max-w-[150px]">
            {file.keywords?.join(", ")}
          </span>
        </div>
      </div>
      {canEdit && (
        <div className="flex space-x-4 flex-shrink-0 ml-10">
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              onEditClick();
            }}
          >
            <PencilIcon className="w-5 h-5" />
          </button>
          <button
            className="text-pantone hover:text-red-700 focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              deleteFile();
            }}
            disabled={loading}
          >
            <TrashIcon className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
}

export default MediaFileContainer;
