import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

type Props = {
  labels: String[];
  values: number[];
  title: string;
  showAsPercentage?: boolean;
};

function PieChart({
  labels,
  values,
  title,
  showAsPercentage = true,
}: Props): JSX.Element {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          "#660000",
          "#990000",
          "#CC3300",
          "#FF3300",
          "#FF6600",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: false, // Allow manual height
    plugins: {
      legend: {
        position: "right",
        labels: {
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
        },
      },
      datalabels: {
        formatter: (value: number, context: any) => {
          if (showAsPercentage) {
            const total = context.dataset.data.reduce(
              (acc: number, val: number) => acc + val,
              0
            );
            const percentage = ((value / total) * 100).toFixed(1);
            return `${percentage}%`;
          }
          return value.toString(); // Show raw count
        },
        color: "#fff",
        font: {
          size: 14,
        },
      },
    },
  };

  return (
    <div style={{ height: "300px" }}>
      <Pie data={data} options={options} />
    </div>
  );
}

export default PieChart;
