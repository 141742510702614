import React, { useState, useCallback } from "react";
import Collapse from "../collapse";
import { useCategoriesContext } from "../../../categories/hooks/use-categories";
import { LoadingBarChart } from "../charts/loading-charts";
import apiService from "../../../services/api-service";
import { getCategorieNamesByIds } from "../../helpers/get-category-names";
import { Category } from "../../../shared/types/categories";
import PieChart from "../charts/pie-chart";

type Props = {
  childCategory: Category;
};

type SubChildData = {
  count: number;
  values: number[];
  labels: number[];
};

function SubChildCollapse({ childCategory }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [dataFetched, setDataFetched] = useState(false);
  const { subChildCategories } = useCategoriesContext();

  const [subChildData, setSubChildData] = useState<SubChildData>({
    count: 0,
    values: [],
    labels: [],
  });

  const fetchReport = useCallback(async () => {
    if (dataFetched || loading) return;
    setLoading(true);
    setError(null);

    const { data: fetchedSubChildData, error: subChildError } =
      await apiService.post<SubChildData>(
        "reports/inventory-subCategoriesReport-summary",
        {
          childCategoryId: childCategory.id,
        }
      );

    if (subChildError) {
      setError(subChildError);
      setLoading(false);
      setDataFetched(true);
      return;
    }

    setSubChildData(fetchedSubChildData!);
    setLoading(false);
    setDataFetched(true);
  }, [dataFetched, loading, childCategory.id]);

  return (
    <Collapse
      title={childCategory?.name || "Category"}
      onToggle={(isOpen) => {
        if (isOpen) fetchReport();
      }}
    >
      <div className="justify-center items-center w-full">
        {loading ? (
          <LoadingBarChart />
        ) : error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <>
            <p className="text-lg text-gray-600">
              Total de registros: {subChildData.count}
            </p>
            <div className="flex-1 min-w-96 mx-auto">
              <PieChart
                labels={getCategorieNamesByIds(
                  subChildData.labels,
                  subChildCategories || []
                )}
                values={subChildData.values}
                title="Subclasificaciónes"
                showAsPercentage={false}
              />
            </div>
          </>
        )}
      </div>
    </Collapse>
  );
}

export default SubChildCollapse;
