import React, { useState, useEffect } from "react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

export type Option = {
  id: string | number;
  name: string;
};

type MultiSelectComboboxProps = {
  options: Option[];
  value?: Option[];
  onChange: (selected: Option[]) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  inLineLabel?: boolean;
};

const MultiSelect = ({
  options,
  value,
  inLineLabel = false,
  onChange,
  label = "Selecciona opciones",
  placeholder = "Selecciona...",
  className = "",
}: MultiSelectComboboxProps) => {
  const [query, setQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    value || [...options]
  );

  useEffect(() => {
    if (value) {
      setSelectedOptions(value);
    }
  }, [value]);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.name.toLowerCase().includes(query.toLowerCase())
        );

  const toggleOption = (option: Option) => {
    let newSelection: Option[];

    if (option.id === "all") {
      // If "Select All" is clicked
      newSelection =
        selectedOptions.length === options.length ? [] : [...options];
    } else {
      // Toggle individual option
      const isSelected = selectedOptions.some(
        (selected) => selected.id === option.id
      );
      if (isSelected) {
        newSelection = selectedOptions.filter(
          (selected) => selected.id !== option.id
        );
      } else {
        newSelection = [...selectedOptions, option];
      }
    }

    setSelectedOptions(newSelection);
    onChange(newSelection);
    setQuery("");
  };

  const displayValue = () => {
    if (selectedOptions.length === 0) return "";
    if (selectedOptions.length === options.length) return "Todo seleccionado";
    if (selectedOptions.length === 1) return selectedOptions[0].name;
    return `${selectedOptions.length} seleccionados`;
  };

  const selectAllOption: Option = { id: "all", name: "Select All" };

  return (
    <Combobox as="div" className={className} multiple>
      <div
        className={twMerge(
          "relative",
          inLineLabel ? "flex space-x-5 items-center" : "block"
        )}
      >
        {label && (
          <Combobox.Label className="block text-sm font-medium text-gray-900 mb-1">
            {label}
          </Combobox.Label>
        )}
        <div className="relative">
          <ComboboxInput
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={displayValue}
            placeholder={placeholder}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
          </ComboboxButton>

          {filteredOptions.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ComboboxOption
                value={selectAllOption}
                className="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-blue-600 hover:text-white"
                onClick={() => toggleOption(selectAllOption)}
              >
                <span className="block truncate font-medium">
                  Todo
                  {selectedOptions.length === options.length && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <CheckIcon className="h-5 w-5" />
                    </span>
                  )}
                </span>
              </ComboboxOption>

              {filteredOptions.map((option) => {
                const isSelected = selectedOptions.some(
                  (selected) => selected.id === option.id
                );

                return (
                  <ComboboxOption
                    key={option.id}
                    value={option}
                    className="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-blue-600 hover:text-white"
                    onClick={() => toggleOption(option)}
                  >
                    <span
                      className={`block truncate ${
                        isSelected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {option.name}
                    </span>
                    {isSelected && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                        <CheckIcon className="h-5 w-5" />
                      </span>
                    )}
                  </ComboboxOption>
                );
              })}
            </ComboboxOptions>
          )}
        </div>
      </div>
    </Combobox>
  );
};

export default MultiSelect;
