import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register Chart.js components and plugins
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

type Props = {
  labels: String[];
  values: number[];
  title: string;
  showValuesAsPercentage?: boolean;
};

export default function BarChart({
  title,
  labels,
  values,
  showValuesAsPercentage = false,
}: Props): JSX.Element {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: ["#660000", "#990000", "#CC3300", "#FF3300"],
        barPercentage: 1.0, // Set to 1.0 to make bars take the full available width
        categoryPercentage: 1.0, // Set to 1.0 to remove spacing between categories
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false, // Allow manual height
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
        },
      },
      datalabels: {
        color: "#ffffff", // Text color
        anchor: "center", // Position in the middle of the bar
        align: "center",
        font: {
          size: 14, // Font size for values
        },
        formatter: (value: number) =>
          `${value} ${showValuesAsPercentage ? "%" : ""}`,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <div style={{ height: "400px" }}>
      <Bar data={data} options={options} />
    </div>
  );
}
