import React, { useCallback, useEffect, useState } from "react";
import { useCategoriesContext } from "../../../categories/hooks/use-categories";
import BarChart from "../charts/bar-chart";
import PieChart from "../charts/pie-chart";
import InventoryCollapse from "./inventory-collapse";
import { LoadingBarChart, LoadingPieChart } from "../charts/loading-charts";
import MultiSelect from "../../../shared/components/general/multi-select";
import apiService from "../../../services/api-service";
import { getCategorieNamesByIds } from "../../helpers/get-category-names";
import CustomDatePicker from "../../../shared/components/general/custom-date-picker";
import CustomButton from "../../../shared/components/general/custom-button";
import toast from "react-hot-toast";
import CustomDropdown from "../../../shared/components/general/custom-dropdown";
import downLoadReport from "../../../shared/utils/download-report";

type CatalogueOption = {
  id: number;
  name: string;
};

type ChartData = {
  labels: string[];
  values: number[];
  parentCategories?: number[];
};

function InventoryReports() {
  const [catalogueOptions, setCatalogueOptions] = useState<CatalogueOption[]>(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState<CatalogueOption[]>([]);
  const [barChartData, setBarChartData] = useState<ChartData>({
    values: [],
    labels: [],
    parentCategories: [],
  });
  const [pieChartData, setPieChartData] = useState<ChartData>({
    values: [],
    labels: [],
  });
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const { parentCategories } = useCategoriesContext();

  const fetchInventorySummary = useCallback(
    async (catalogueIds: number[], startDate?: string, endDate?: string) => {
      const { data: summaryData } = await apiService.post(
        "reports/inventory-summary",
        {
          catalogueIds,
          startDate,
          endDate,
        }
      );

      setBarChartData({
        labels: summaryData.barChart.labels,
        values: summaryData.barChart.values,
        parentCategories: summaryData.barChart.parentCategories,
      });

      setPieChartData({
        labels: getCategorieNamesByIds(
          summaryData.pieChart.labels,
          parentCategories || []
        ),
        values: summaryData.pieChart.values,
      });
    },
    [parentCategories]
  );

  const fetchCatalogueOptions = useCallback(async () => {
    const { data: catalogues, error } = await apiService.get("catalogues");

    if (error) {
      console.error("Error fetching catalogue options", error);
      return [];
    }

    setCatalogueOptions(catalogues);
    setSelectedOptions(catalogues);
    return catalogues.map((option: CatalogueOption) => option.id);
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      const catalogueIds = await fetchCatalogueOptions();
      if (catalogueIds.length > 0) {
        await fetchInventorySummary(catalogueIds);
      }
      setLoading(false);
    };
    initializeData();
  }, [fetchCatalogueOptions, fetchInventorySummary]);

  const handleSelectionChange = async (selected: CatalogueOption[]) => {
    setSelectedOptions(selected);
  };

  const handleApplySearch = async (
    selected: CatalogueOption[],
    start?: string,
    end?: string
  ) => {
    if (
      start !== undefined &&
      end !== undefined &&
      new Date(start) > new Date(end)
    ) {
      toast.error("La fecha de inicio no puede ser mayor que la fecha de fin");
      return;
    }

    setLoading(true);
    await fetchInventorySummary(
      selected.map((option) => option.id),
      start,
      end
    );
    setLoading(false);
  };

  const handleStartDateChange = (date: string) => {
    setStartDate(date);
    if (!endDate || new Date(date) > new Date(endDate)) {
      setEndDate(date);
    }
  };

  const resetState = async () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setSelectedOptions(catalogueOptions);

    handleApplySearch(catalogueOptions, undefined, undefined);
  };

  async function handleDownloadFile(type: string) {
    const ids = selectedOptions?.map((option) => option.id);
    await downLoadReport(type, ids, startDate, endDate);
  }

  return (
    <div className="flex flex-col items-start justify-center w-full">
      <div className="mb-5 flex flex-wrap justify-center gap-4 items-center w-full ">
        <MultiSelect
          label="Filtrar por inventario"
          options={catalogueOptions}
          onChange={(selected) => {
            handleSelectionChange(selected as CatalogueOption[]);
          }}
          value={selectedOptions}
        />

        <CustomDatePicker
          label="Desde"
          placeholder="Fecha de inicio"
          date={startDate?.toString()!}
          onDateChange={handleStartDateChange}
        />

        <CustomDatePicker
          date={endDate?.toString()!}
          label="Hasta"
          onDateChange={setEndDate}
        />
        <div className="ml-auto space-x-4">
          <CustomButton
            onClick={() =>
              handleApplySearch(selectedOptions, startDate, endDate)
            }
            title="Aplicar"
            className="mt-5"
          />
          <CustomButton
            onClick={() => {
              resetState();
            }}
            title="Limpiar filtros"
            filled={false}
            className="mt-5"
          />
          <CustomDropdown
            title="Descargar"
            menuItems={[
              { title: "Excel", onClick: () => handleDownloadFile("excel") },
              { title: "CSV", onClick: () => handleDownloadFile("csv") },
            ]}
          />
        </div>
      </div>

      <div className="w-full border-b border-gray-400 mb-4">
        <p className="text-xl text-left font-bold">Resumen de Inventarios</p>
      </div>

      {loading ? (
        <div className="flex flex-wrap gap-5 justify-center items-center w-full">
          <div className="flex-1 min-w-96">
            <LoadingBarChart />
          </div>
          <div className="flex-1 min-w-96 ml-32">
            <LoadingPieChart />
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-wrap gap-5 justify-center items-center w-full">
            <div className="flex-1 min-w-96">
              <BarChart
                labels={barChartData.labels}
                values={barChartData.values}
                title="BDC"
              />
            </div>
            <div className="flex-1 min-w-96">
              <PieChart
                title="Registros por Artefacto"
                values={pieChartData.values}
                labels={pieChartData.labels}
                showAsPercentage={false}
              />
            </div>
          </div>
          <div className="w-full border-b border-gray-400 my-4">
            <p className="text-xl text-left font-bold">Resumen por artefacto</p>
          </div>
          {parentCategories?.map((cat) => (
            <InventoryCollapse key={cat.id} categoryId={cat.id} />
          ))}
        </>
      )}
    </div>
  );
}

export default InventoryReports;
