import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { APPCONFIG } from "../config";
import Cookies from "js-cookie";
import { saveAuthCookies } from "../shared/utils/auth";

class ApiService {
  baseUrl: string;
  authToken?: string | null;
  service: AxiosInstance;

  constructor() {
    this.baseUrl = APPCONFIG.baseApiUrl;
    this.service = axios.create({
      baseURL: this.baseUrl,
    });

    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalRequest: any = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          const refreshToken = Cookies.get("refreshToken");
          const email = Cookies.get("email");

          if (!refreshToken || !email) {
            return Promise.reject(error);
          }

          try {
            const response = await axios.post(
              `${this.baseUrl}/auth/refreshSession`,
              {
                refreshToken,
                email,
              }
            );

            const token = response.data.accessToken.jwtToken;
            saveAuthCookies(response.data);
            this.service.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            originalRequest.headers["Authorization"] = "Bearer " + token;
            this.authToken = token;

            console.log("Session refreshed successfully");
            // Retry the original request with the new token
            return this.service(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  constructHeaders(params?: any, isFileUpload = false) {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${this.authToken}`,
    };

    if (!isFileUpload) {
      headers["Content-Type"] = "application/json";
    }

    return {
      headers,
      withCredentials: false,
      params,
    };
  }

  // NOTE: dont use try catch when calling any method from this class, here is an example use:
  //   const { data, error } = await apiService.get<YourResponseType>('endpointhere');
  //   if (error) do something with eror

  async get<t = any>(endpoint: string, params?: Record<string, any> | null) {
    try {
      const completeUrl = `${this.baseUrl}/${endpoint}`;
      const { responseType, ...restParams } = params || {};
      const axiosConfig = {
        ...this.constructHeaders(restParams),
        responseType: responseType ?? "json",
      };
      const { data } = await this.service.get<t>(completeUrl, axiosConfig);
      return {
        data,
      };
    } catch (error: any) {
      const responseData = error.response?.data;
      console.log("API ERROR: ", responseData?.message ?? "");

      return { error: responseData?.message };
    }
  }

  async post<t = any>(
    endpoint: string,
    body: any,
    params?: Record<string, any> | null
  ) {
    try {
      const completeUrl = `${this.baseUrl}/${endpoint}`;
      const { data } = await this.service.post<t>(
        completeUrl,
        body,
        this.constructHeaders(params)
      );

      return {
        data,
      };
    } catch (error: any) {
      const responseData = error.response?.data;
      const apiError =
        responseData?.message ||
        responseData?.message ||
        responseData.code ||
        "error";
      console.log("API ERROR: ", apiError);
      return { error: apiError };
    }
  }

  async put<t = any>(endpoint: string, body: any) {
    try {
      const completeUrl = `${this.baseUrl}/${endpoint}`;
      const { data } = await this.service.put<t>(
        completeUrl,
        body,
        this.constructHeaders()
      );

      return {
        data,
      };
    } catch (error: any) {
      const responseData = error.response?.data;
      console.log("API ERROR: ", responseData?.message);
      return { error: responseData?.message };
    }
  }

  async delete<t = any>(endpoint: string) {
    try {
      const completeUrl = `${this.baseUrl}/${endpoint}`;
      const { data } = await this.service.delete<t>(
        completeUrl,
        this.constructHeaders()
      );

      return {
        data,
      };
    } catch (error: any) {
      const responseData = error.response?.data;
      console.log("API ERROR: ", responseData?.message);
      return { error: responseData?.message };
    }
  }

  async uploadFile<t = any>(
    endpoint: string,
    file?: File | null,
    method = "post",
    bodyParams?: Record<string, any>
  ) {
    try {
      const completeUrl = `${this.baseUrl}/${endpoint}`;
      const formData = new FormData();

      if (file) {
        formData.append("file", file);
      }

      if (bodyParams) {
        for (const key in bodyParams) {
          if (bodyParams.hasOwnProperty(key)) {
            const value = bodyParams[key];
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          }
        }
      }

      if (method === "put") {
        const { data } = await this.service.put<t>(
          completeUrl,
          formData,
          this.constructHeaders({}, true)
        );
        return {
          data,
        };
      }

      const { data } = await this.service.post<t>(
        completeUrl,
        formData,
        this.constructHeaders({}, true)
      );

      return {
        data,
      };
    } catch (error: any) {
      const responseData = error.response?.data;
      const apiError =
        responseData?.message || responseData?.message || "error";
      console.log("API ERROR: ", apiError);
      return { error: apiError };
    }
  }
}

const apiService = new ApiService();
export default apiService;
