import React from "react";

export function LoadingBarChart() {
  return (
    <div
      role="status"
      className=" p-4   rounded  animate-pulse md:p-6  w-full "
    >
      <div className="h-2.5  bg-gray-300 rounded-full  w-32 mb-2.5"></div>
      <div className="w-48 h-2 mb-10 bg-gray-300 rounded-full"></div>
      <div className="flex items-baseline mt-4 space-x-6">
        <div className="w-full bg-gray-300 rounded-t-lg h-40 "></div>
        <div className="w-full h-56 bg-gray-200 rounded-t-lg "></div>
        <div className="w-full bg-gray-300 rounded-t-lg h-40 "></div>
        <div className="w-full h-64 bg-gray-300 rounded-t-lg "></div>
        <div className="w-full bg-gray-300 rounded-t-lg h-60 "></div>
        <div className="w-full bg-gray-300 rounded-t-lg h-40 "></div>
        <div className="w-full bg-gray-300 rounded-t-lg h-40 "></div>
      </div>
    </div>
  );
}

export function LoadingPieChart() {
  return (
    <div
      role="status"
      className=" p-4   rounded  animate-pulse md:p-6  w-full "
    >
      <div className="h-2.5  bg-gray-300 rounded-full  w-32 mb-2.5"></div>
      <div className="w-48 h-2 mb-10 bg-gray-300 rounded-full"></div>

      {/* Pie chart wrapper */}
      <div className="relative w-64 h-64 rounded-full overflow-hidden">
        {/* Slice 1 */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-300"
          style={{ clipPath: "polygon(50% 50%, 100% 0%, 100% 100%)" }}
        ></div>

        {/* Slice 2 */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-200"
          style={{ clipPath: "polygon(50% 50%, 100% 100%, 0% 100%)" }}
        ></div>

        {/* Slice 3 */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-300"
          style={{ clipPath: "polygon(50% 50%, 0% 100%, 0% 0%)" }}
        ></div>

        {/* Slice 4 */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-gray-200"
          style={{ clipPath: "polygon(50% 50%, 0% 0%, 100% 0%)" }}
        ></div>
      </div>
    </div>
  );
}
