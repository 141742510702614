export default function getPageName(path: string) {
  const pathArray = path.split("/");

  if (pathArray.length === 3) {
    return pathArray[1];
  }

  const pageName = pathArray[pathArray.length - 1];
  return pageName;
}
