import React from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import { MediaFile } from "../../shared/types/section";
import { CatalogueItem } from "../../shared/types/catalogues";
import apiService from "../../services/api-service";
import { useCanEditCatalogue } from "../../wiki/hooks/use-canEdit";
import CustomDropdown from "../../shared/components/general/custom-dropdown";

type Props = {
  mediaFile: MediaFile;
  catalogueItem: CatalogueItem;
  onFileDeleted: (file: MediaFile) => void;
  openFileDialog: (file: MediaFile) => void;
};

function FileContainer({
  mediaFile,
  catalogueItem,
  openFileDialog,
  onFileDeleted,
}: Props) {
  const baseCataloguesUrl = `catalogues/${catalogueItem.catalogue?.id}/items/${catalogueItem.id}`;
  const canEdit = useCanEditCatalogue(catalogueItem?.catalogue!);

  const deleteFile = async () => {
    onFileDeleted(mediaFile);
    const { error } = await apiService.delete(
      `${baseCataloguesUrl}/files/${mediaFile.id}`
    );
    if (error) {
      toast.error("Error al eliminar el archivo");
      return;
    }
  };

  async function getPresignedUrl() {
    const { data, error } = await apiService.get(
      `${baseCataloguesUrl}/files/${mediaFile.id}/signedUrl`
    );

    if (error) {
      toast.error("Error al obtener el archivo");
      return;
    }

    window.open(data, "_blank");
  }

  return (
    <li className="group  relative flex-shrink-0 w-[350px] flex items-center justify-between py-4 pl-4 pr-5 text-xs leading-6 border border-gray-200 rounded-md">
      <div className="flex w-0 flex-1 items-center">
        <PaperClipIcon aria-hidden="true" className="h-4 w-4 text-gray-400" />
        <div className="ml-4 flex min-w-0 flex-1 gap-2">
          <span className="truncate font-medium">{mediaFile.fileName}</span>
          <span className="text-gray-400">{mediaFile.size}mb</span>
        </div>
      </div>
      <div className="ml-4 flex items-center space-x-2">
        <button
          className="font-medium text-blue-600 hover:text-blue-500"
          onClick={() => getPresignedUrl()}
        >
          Descargar
        </button>

        {canEdit && (
          <CustomDropdown
            withThreeDots
            title=""
            menuItems={[
              {
                title: "Editar",
                onClick: () => {
                  openFileDialog(mediaFile);
                },
              },
              {
                title: "Eliminar",
                onClick: () => {
                  deleteFile();
                },
              },
            ]}
          />
        )}
      </div>
    </li>
  );
}

export default FileContainer;
