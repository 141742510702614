import { Category } from "../../shared/types/categories";

export const getCategorieNamesByIds = (
  ids: number[],
  categories: Category[]
) => {
  return (
    categories
      ?.filter((category) => ids.includes(category.id))
      .map((category) => category.name) || []
  );
};
