import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { Section } from "../../types/section";
import CustomDialog from "./custom-dialog";
import CreateSectionForm from "../../../wiki/components/CreateSectionForm";
import CreateCatalogForm from "../../../catalogues/components/create-catalogue-form";
import apiService from "../../../services/api-service";
import toast from "react-hot-toast";
import useCurrentSite from "../../hooks/useCurrentSite";

enum Tab {
  SECTION = "section",
  CATALOGUE = "catalogue",
  LOADING = "loading",
}

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  pageName: string;
};

export default function CreateContentDialog({
  open,
  setOpen,
  pageName,
}: Props) {
  const [currentTab, setCurrentTab] = React.useState<Tab>(Tab.SECTION);
  const [newCreatedSection, setNewCreatedSection] =
    React.useState<Section | null>();
  const currentSite = useCurrentSite();

  const navigation = [
    { name: "Nueva Sección", tab: Tab.SECTION },
    { name: "Nuevo Inventario", tab: Tab.CATALOGUE },
  ];

  async function close() {
    if (newCreatedSection) {
      await apiService.delete(`sections/${newCreatedSection!.id}`);
    }
    setCurrentTab(Tab.SECTION);
    setNewCreatedSection(null);
    setOpen(false);
  }

  async function createTemplateSection() {
    if (newCreatedSection) {
      setCurrentTab(Tab.SECTION);
      return;
    }

    setCurrentTab(Tab.LOADING);
    const { data, error } = await apiService.post<Section>("sections", {
      pageName: pageName,
      siteId: currentSite!.id,
      title: "-",
      content: "-",
    });

    if (error) {
      toast.error("Se produjo un error, intentalo de nuevo");
      setCurrentTab(Tab.CATALOGUE);
      return;
    }
    setNewCreatedSection(data!);
    setCurrentTab(Tab.SECTION);
  }

  useEffect(() => {
    if (open) {
      createTemplateSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function Content() {
    if (currentTab === Tab.SECTION) {
      return (
        <CreateSectionForm
          backToIdle={() => {
            setCurrentTab(Tab.CATALOGUE);
            setNewCreatedSection(null);
            setOpen(false);
          }}
          newCreatedSection={newCreatedSection}
          onClose={() => close()}
          setNewCreatedSection={setNewCreatedSection}
          site={currentSite!}
        />
      );
    }

    if (currentTab === Tab.LOADING) {
      return (
        <div className="space-y-6 p-6 animate-pulse">
          <div>
            <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>{" "}
            <div className="h-10 bg-gray-300 rounded w-full"></div>{" "}
          </div>
          <div>
            <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>{" "}
            <div className="h-32 bg-gray-300 rounded w-full"></div>{" "}
          </div>
          <div className="h-10 bg-gray-300 rounded w-1/4"></div>
        </div>
      );
    }

    return (
      <CreateCatalogForm
        backToIdle={() => setOpen(false)}
        site={currentSite!}
        onClose={() => close()}
      />
    );
  }

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      dialogTitle="Crear Contenido"
      onClose={() => close()}
      large={true}
    >
      <div className="mb-4 border-b border-gray-200 ">
        <div className="flex space-x-8">
          {navigation.map((item) => (
            <button
              key={item.name}
              className={twMerge(
                item.tab === currentTab
                  ? "border-pantone text-gray-900"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium"
              )}
              onClick={async () => {
                if (item.tab === Tab.SECTION) {
                  await createTemplateSection();
                }
                setCurrentTab(item.tab);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>

      <Content />
    </CustomDialog>
  );
}
